// modelLinks.js
export const modelLinks = {
    "chatgpt-4o-latest-0903": { url: "https://openai.com/index/hello-gpt-4o/", organization: "OpenAI" },
    "chatgpt-4o-latest-2025-01-29": { url: "https://help.openai.com/en/articles/9624314-model-release-notes", organization: "OpenAI" },
    "chatgpt-4o-latest-2025-03-27": { url: "https://x.com/OpenAIDevs/status/1905335104211185999?t=pmYR2_xGFyWs1xOGuNxRsw&s=19", organization: "OpenAI" },
    "claude-3-5-sonnet-20240620": { url: "https://www.anthropic.com/news/claude-3-5-sonnet", organization: "Anthropic" },
    "claude-3-5-sonnet-20241022": { url: "https://www.anthropic.com/news/3-5-models-and-computer-use", organization: "Anthropic" },
    "claude-3-5-haiku-20241022": { url: "https://www.anthropic.com/claude/haiku", organization: "Anthropic" },
    "claude-3-haiku-20240307": { url: "https://www.anthropic.com/claude", organization: "Anthropic" },
    "claude-3-opus-20240229": { url: "https://www.anthropic.com/claude", organization: "Anthropic" },
    "claude-3-sonnet-20240229": { url: "https://www.anthropic.com/claude", organization: "Anthropic" },
    "claude-3-7-sonnet-thinking": { url: "https://www.anthropic.com/news/claude-3-7-sonnet", organization: "Anthropic"},
    "claude-3-7-sonnet": { url: "https://www.anthropic.com/news/claude-3-7-sonnet", organization: "Anthropic"},
    "command-r": { url: "https://docs.cohere.com/docs/models", organization: "Cohere" },
    "command-r-08-2024": { url: "https://docs.cohere.com/docs/models", organization: "Cohere" },
    "command-r-plus": { url: "https://docs.cohere.com/docs/models", organization: "Cohere" },
    "command-r-plus-04-2024": { url: "https://cohere.com/blog/command-r-plus-microsoft-azure", organization: "Cohere" },
    "command-r-plus-08-2024": { url: "https://docs.cohere.com/docs/models", organization: "Cohere" },
    "deepseek-coder-v2": { url: "https://huggingface.co/deepseek-ai/DeepSeek-V2", organization: "DeepSeek" },
    "deepseek-v2.5": { url: "https://huggingface.co/deepseek-ai/DeepSeek-V2.5", organization: "DeepSeek" },
    "deepseek-v2.5-1210": { url: "https://api-docs.deepseek.com/news/news1210", organization: "DeepSeek" },
    "deepseek-v3": { url: "https://api-docs.deepseek.com/news/news1226", organization: "DeepSeek" },
    "deepseek-r1": { url: "https://huggingface.co/deepseek-ai/DeepSeek-R1", organization: "DeepSeek" },
    "deepseek-r1-distill-qwen-32b": { url: "https://huggingface.co/deepseek-ai/DeepSeek-R1-Distill-Qwen-32B", organization: "DeepSeek" },
    "deepseek-r1-distill-llama-70b": { url: "https://huggingface.co/deepseek-ai/DeepSeek-R1-Distill-Llama-70B", organization: "DeepSeek" },
    "dracarys-72b-instruct": { url: "https://huggingface.co/abacusai/Dracarys-72B-Instruct", organization: "AbacusAI" },
    "dracarys-llama-3.1-70b-instruct": { url: "https://huggingface.co/abacusai/Dracarys-Llama-3.1-70B-Instruct", organization: "AbacusAI" },
    "dracarys2-72b-instruct": { url: "https://huggingface.co/abacusai/Dracarys2-72B-Instruct", organization: "AbacusAI" },
    "dracarys2-llama-3.1-70b-instruct": { url: "https://huggingface.co/abacusai/Dracarys2-Llama-3.1-70B-Instruct", organization: "AbacusAI" },
    "gemini-1.5-flash-001": { url: "https://console.cloud.google.com/vertex-ai/publishers/google/model-garden/gemini-1.5-flash-001", organization: "Google" },
    "gemini-1.5-flash-002": { url: "https://developers.googleblog.com/en/updated-production-ready-gemini-models-reduced-15-pro-pricing-increased-rate-limits-and-more/", organization: "Google" },
    "gemini-1.5-flash-8b-exp-0827": { url: "https://ai.google.dev/gemini-api/docs/models/experimental-models", organization: "Google" },
    "gemini-1.5-flash-8b-exp-0924": { url: "https://ai.google.dev/gemini-api/docs/models/gemini#gemini-1.5-flash-8b", organization: "Google" },
    "gemini-1.5-flash-api-0514": { url: "https://console.cloud.google.com/vertex-ai/publishers/google/model-garden/gemini-1.5-flash-preview-0514", organization: "Google" },
    "gemini-1.5-flash-exp-0827": { url: "https://ai.google.dev/gemini-api/docs/models/experimental-models", organization: "Google" },
    "gemini-1.5-pro-001": { url: "https://console.cloud.google.com/vertex-ai/publishers/google/model-garden/gemini-1.5-pro-001", organization: "Google" },
    "gemini-1.5-pro-002": { url: "https://developers.googleblog.com/en/updated-production-ready-gemini-models-reduced-15-pro-pricing-increased-rate-limits-and-more/", organization: "Google" },
    "gemini-1.5-pro-api-0514": { url: "https://console.cloud.google.com/vertex-ai/publishers/google/model-garden/gemini-1.5-flash-preview-0514", organization: "Google" },
    "gemini-1.5-pro-exp-0801": { url: "https://ai.google.dev/gemini-api/docs/models/experimental-models", organization: "Google" },
    "gemini-1.5-pro-exp-0827": { url: "https://ai.google.dev/gemini-api/docs/models/experimental-models", organization: "Google" },
    "gemini-2.0-flash-exp": { url: "https://cloud.google.com/vertex-ai/generative-ai/docs/gemini-v2", organization: "Google" },
    "gemini-2.0-flash": { url: "https://blog.google/technology/google-deepmind/gemini-model-updates-february-2025/", organization: "Google" },
    "gemini-2.0-flash-thinking-exp-1219": { url: "https://ai.google.dev/gemini-api/docs/thinking-mode", organization: "Google" },
    "gemini-2.0-flash-thinking-exp-01-21": { url: "https://ai.google.dev/gemini-api/docs/thinking-mode", organization: "Google" },
    "gemini-2.0-flash-lite-preview-02-05": { url: "https://blog.google/technology/google-deepmind/gemini-model-updates-february-2025/", organization: "Google" },
    "gemini-2.0-pro-exp-02-05": { url: "https://blog.google/technology/google-deepmind/gemini-model-updates-february-2025/", organization: "Google" },
    "gemini-exp-1114": { url: "https://ai.google.dev/gemini-api/docs/models/experimental-models", organization: "Google" },
    "gemini-exp-1121": { url: "https://ai.google.dev/gemini-api/docs/models/experimental-models", organization: "Google" },
    "gemini-exp-1206": { url: "https://ai.google.dev/gemini-api/docs/models/experimental-models", organization: "Google" },
    "gemma-2-27b-it": { url: "https://huggingface.co/google/gemma-2-27b", organization: "Google" },
    "gemma-2-9b-it": { url: "https://huggingface.co/google/gemma-2-9b", organization: "Google" },
    "gpt-3.5-turbo-0125": { url: "https://openai.com/index/new-embedding-models-and-api-updates/", organization: "OpenAI" },
    "gpt-4-0125-preview": { url: "https://openai.com/index/new-models-and-developer-products-announced-at-devday/", organization: "OpenAI" },
    "gpt-4-0613": { url: "https://openai.com/index/new-models-and-developer-products-announced-at-devday/", organization: "OpenAI" },
    "gpt-4-turbo-2024-04-09": { url: "https://openai.com/index/new-models-and-developer-products-announced-at-devday/", organization: "OpenAI" },
    "gpt-4o-2024-05-13": { url: "https://openai.com/index/hello-gpt-4o/", organization: "OpenAI" },
    "gpt-4o-2024-08-06": { url: "https://openai.com/index/hello-gpt-4o/", organization: "OpenAI" },
    "gpt-4o-2024-11-20": { url: "https://openai.com/index/hello-gpt-4o/", organization: "OpenAI" },
    "gpt-4o-mini-2024-07-18": { url: "https://openai.com/index/hello-gpt-4o/", organization: "OpenAI" },
    "grok-2": { url: "https://x.ai/blog/grok-2", organization: "xAI" },
    "grok-2-mini": { url: "https://x.ai/blog/grok-2", organization: "xAI" },
    "grok-2-1212": { url: "https://x.ai/blog/grok-1212", organization: "xAI" },
    "lama-3.1-nemotron-70b-instruct": { url: "https://build.nvidia.com/nvidia/llama-3_1-nemotron-70b-instruct", organization: "NVIDIA" },
    "meta-llama-3.1-405b-instruct-turbo": { url: "https://www.together.ai/blog/meta-llama-3-1", organization: "Meta" },
    "meta-llama-3.1-70b-instruct-turbo": { url: "https://www.together.ai/blog/meta-llama-3-1", organization: "Meta" },
    "meta-llama-3.1-8b-instruct-turbo": { url: "https://www.together.ai/blog/meta-llama-3-1", organization: "Meta" },
    "llama-3.3-70b-instruct-turbo": { url: "https://huggingface.co/meta-llama/Llama-3.3-70B-Instruct", organization: "Meta" },
    "mistral-large-2407": { url: "https://huggingface.co/mistralai/Mistral-Large-Instruct-2407", organization: "Mistral AI" },
    "mistral-large-2411": { url: "https://huggingface.co/mistralai/Mistral-Large-Instruct-2411", organization: "Mistral AI" },
    "mistral-small-2402": { url: "https://docs.mistral.ai/getting-started/models/", organization: "Mistral AI" },
    "mistral-small-2409": { url: "https://huggingface.co/mistralai/Mistral-Small-Instruct-2409", organization: "Mistral AI" },
    "mistral-small-2501": { url: "https://mistral.ai/en/news/mistral-small-3", organization: "Mistral AI" },
    "mixtral-8x22b-instruct-v0.1": { url: "https://huggingface.co/mistralai/Mixtral-8x22B-Instruct-v0.1", organization: "Mistral AI" },
    "o1-mini-2024-09-12": { url: "https://platform.openai.com/docs/guides/reasoning", organization: "OpenAI" },
    "o1-preview-2024-09-12": { url: "https://platform.openai.com/docs/guides/reasoning", organization: "OpenAI" },
    "o1-2024-12-17": { url: "https://openai.com/o1/", organization: "OpenAI" },
    "o1-2024-12-17-high": { url: "https://openai.com/o1/", organization: "OpenAI" },
    "o1-2024-12-17-low": { url: "https://openai.com/o1/", organization: "OpenAI" },
    "o3-mini-2025-01-31-high": { url: "https://openai.com/index/openai-o3-mini/", organization: "OpenAI" },
    "o3-mini-2025-01-31-low": { url: "https://openai.com/index/openai-o3-mini/", organization: "OpenAI" },
    "o3-mini-2025-01-31-medium": { url: "https://openai.com/index/openai-o3-mini/", organization: "OpenAI" },
    "o3-mini-2025-01-31": { url: "https://openai.com/index/openai-o3-mini/", organization: "OpenAI" },
    "open-mistral-nemo": { url: "https://huggingface.co/mistralai/Mistral-Nemo-Instruct-2407", organization: "Mistral AI" },
    "phi-3-medium-128k-instruct": { url: "https://huggingface.co/microsoft/Phi-3-medium-128k-instruct", organization: "Microsoft" },
    "phi-3-medium-4k-instruct": { url: "https://huggingface.co/microsoft/Phi-3-medium-4k-instruct", organization: "Microsoft" },
    "phi-3-mini-128k-instruct": { url: "https://huggingface.co/microsoft/Phi-3-mini-128k-instruct", organization: "Microsoft" },
    "phi-3-mini-4k-instruct": { url: "https://huggingface.co/microsoft/Phi-3-mini-4k-instruct", organization: "Microsoft" },
    "phi-3-small-128k-instruct": { url: "https://huggingface.co/microsoft/Phi-3-small-128k-instruct", organization: "Microsoft" },
    "phi-3-small-8k-instruct": { url: "https://huggingface.co/microsoft/Phi-3-small-8k-instruct", organization: "Microsoft" },
    "phi-3.5-mini-instruct": { url: "https://huggingface.co/microsoft/Phi-3.5-mini-instruct", organization: "Microsoft" },
    "phi-3.5-moe-instruct": { url: "https://huggingface.co/microsoft/Phi-3.5-MoE-instruct", organization: "Microsoft" },
    "phi-4": { url: "https://huggingface.co/microsoft/Phi-4", organization: "Microsoft" },
    "qwen2.5-72b-instruct-turbo": { url: "https://huggingface.co/Qwen/Qwen2.5-72B-Instruct", organization: "Alibaba" },
    "qwen2.5-7b-instruct-turbo": { url: "https://huggingface.co/Qwen/Qwen2.5-7B-Instruct", organization: "Alibaba" },
    "qwen2.5-coder-32b-instruct": { url: "https://huggingface.co/Qwen/Qwen2.5-Coder-32B-Instruct", organization: "Alibaba" },
    "qwen2.5-max": { url: "https://qwenlm.github.io/blog/qwen2.5-max/", organization: "Alibaba" },
    "step-2-16k-202411": { url: "https://www.stepfun.com/#step2", organization: "StepFun" },
    "grok-beta": { url: "https://x.ai/blog/api", organization: "xAI" },
    "amazon.nova-lite-v1:0": { url: "https://aws.amazon.com/ai/generative-ai/nova/", organization: "Amazon" },
    "amazon.nova-micro-v1:0": { url: "https://aws.amazon.com/ai/generative-ai/nova/", organization: "Amazon" },
    "amazon.nova-pro-v1:0": { url: "https://aws.amazon.com/ai/generative-ai/nova/", organization: "Amazon" },
    "qwq-32b-preview": { url: "https://huggingface.co/Qwen/QWQ-32B-Preview", organization: "Alibaba" },
    "olmo-2-1124-13b-instruct": {url: "https://huggingface.co/allenai/OLMo-2-1124-13B-Instruct", organization: "AllenAI"},
    "learnlm-1.5-pro-experimental": {url: "https://ai.google.dev/gemini-api/docs/learnlm", organization: "Google"},
    "grok-3-thinking": {url: "https://x.ai/blog/grok-3", organization: "xAI"},
    "grok-3": {url: "https://x.ai/blog/grok-3", organization: "xAI"},
    "gpt-4.5-preview": {url: "https://openai.com/index/introducing-gpt-4-5/", organization: "OpenAI"},
    "gemini-2.0-flash-lite": {url: "https://developers.googleblog.com/en/start-building-with-the-gemini-2-0-flash-family/", organization: "Google"},
    "qwq-32b": { url: "https://qwenlm.github.io/blog/qwq-32b/", organization: "Alibaba"},
    "mistral-small-2503": { url: "https://mistral.ai/news/mistral-small-3-1", organization: "Mistral AI"},
    "gemma-3-27b-it": { url: "https://blog.google/technology/developers/gemma-3/", organization: "Google" },
    "perplexity-sonar": { url: "https://perplexity.ai/", organization: "Perplexity" },
    "perplexity-sonar-pro": { url: "https://perplexity.ai/", organization: "Perplexity" },
    "deepseek-v3-0324": {url: "https://huggingface.co/deepseek-ai/DeepSeek-V3-0324", organization: "DeepSeek"},
    "gemini-2.5-pro-exp-03-25": {url: "https://blog.google/technology/google-deepmind/gemini-model-thinking-updates-march-2025/", organization: "Google"},
    "hunyuan-turbos-20250313": {url: "https://cloud.tencent.com/document/product/1729/104753", organization: "Tencent"},
    "azerogpt": {url: "https://azero.soundai.com/#/dashboard", organization: "SoundAI" }
};
